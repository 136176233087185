<template>
    <Page icon="mdi-calendar" :title="$t('routes.calendar')">
        <Calendar />
    </Page>
</template>

<script>
import Page from "./components/Page";
import Calendar from "./components/Calendar.vue"

import validator from "../plugins/validator";
import * as calendarValidation from "../api/calendar/validation";

export default {
    components: {
        Page,
        Calendar
    },
};
</script>
