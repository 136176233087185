<template>
    <v-snackbar
        :value="open"
        @input="handleInput"
        :timeout="timeout"
        :centered="true"
    >
        <v-icon left color="primary">mdi-check</v-icon>
        {{ text }}
    </v-snackbar>
</template>

<script>
export default {
    props: {
        text: {
            type: String
        },

        open: {
            type: Boolean,
            default: false
        },
    },

    model: {
        prop: 'open',
        event: 'update'
    },

    data: (vm) => ({
        timeout: 2000,
    }),

    methods:{
        handleInput(value){
            this.$emit('update', value);
        }
    }
}
</script>